import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {User} from '../../../../model/user.model';
import {ClientService} from '../../../../services/client.service';
import {RoleService} from '../../../../services/role.service';
import {forkJoin, Observable} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import {LoaderService} from '../../../../services/loader.service';
import {UserService} from '../../../../services/user.service';
import {Client} from '../../../../model/client.model';
import {OrganizationType} from '../../../../model/organization-type.model';
import {Municipality} from '../../../../model/municipality.model';
import {MatSelectChange} from '@angular/material/select';
import {MunicipalityService} from '../../../../services/municipality.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {AuthService} from '../../../../services/auth.service';

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

	user: User;
	recognizedUser: User;
	client: Client;
	organizationTypes: OrganizationType[];
	provinces: Municipality[];
	municipalities: Municipality[];

	clientUI: any;

	userAlreadyExists: boolean;
	userHasClient: boolean;
	validToken: boolean;

	public language: string;
	public labels = {
		'IT': {
			'template.createProfile.part1': 'CREAZIONE ACCOUNT',
			'template.createProfile.part2': 'SU',
			'template.personalInfo': 'Informazioni personali',
			'template.form.choose': 'Scegli...',
			'template.form.name': 'Nome',
			'template.form.surname': 'Cognome',
			'template.form.address': 'Indirizzo',
			'template.form.organization': 'Organizzazione',
			'template.form.province': 'Provincia',
			'template.form.municipality': 'Comune',
			'template.form.organizationType': 'Tipo di Organizzazione',
			'template.form.phone': 'Telefono',
			'template.form.credential': 'Credenziali',
			'template.form.password': 'Password *',
			'template.form.password.confirm': 'Conferma password *',
			'template.form.otherInformation': 'Altre informazioni',
			'template.form.privacy.title': 'PRIVACY POLICY',
			'template.form.privacy.part1': 'Dichiaro di aver letto la',
			'template.form.privacy.part2': 'privacy policy',
			'template.form.privacy.part3': 'e di accettare il trattamento dei dati.',
			'template.form.privacy.yes': 'Sì',
			'template.form.privacy.no': 'No',
			'template.form.error': 'L\'e-mail è già presente nell\'identity provider.',
			'template.form.error.message1': 'Si prega di tornare indietro e di effettuare il login.',
			'template.form.error.message2': 'Inserire la password attuale per continuare.',
			'template.form.required': 'Campo obbligatorio.',
			'template.form.btn.signup': 'Iscriviti',
			'template.tokenFlow.welcome': 'Benvenuto, ',
			'template.tokenFlow.userAlreadyRegistered': 'L\'account risulta gia registrato su ',
			'template.tokenFlow.error.message1': 'Si prega di tornare indietro per effettuare il login o di cliccare il bottone qui sotto per registrarsi con un altro account.',
			'template.tokenFlow.error.message2': 'Per proseguire con questo account clicca su \'Prosegui\'. Per effettuare la registrazione con un altro account clicca su \'Nuova registrazione\'.',
			'template.tokenFlow.btn.restart': 'Nuova registrazione',
			'template.tokenFlow.btn.continue': 'Prosegui'
		},
		'EN': {
			'template.createProfile.part1': 'CREATE ACCOUNT',
			'template.createProfile.part2': 'ON',
			'template.personalInfo': 'Personal info',
			'template.form.choose': 'Choose...',
			'template.form.name': 'Name',
			'template.form.surname': 'Surname',
			'template.form.address': 'Address',
			'template.form.organization': 'Organization',
			'template.form.province': 'Province',
			'template.form.municipality': 'Municipality',
			'template.form.organizationType': 'Organization Type',
			'template.form.phone': 'Phone',
			'template.form.credential': 'Credential',
			'template.form.password': 'Password *',
			'template.form.password.confirm': 'Password confirm *',
			'template.form.otherInformation': 'Other information',
			'template.form.privacy.title': 'PRIVACY POLICY',
			'template.form.privacy.part1': 'I declare that I have read the',
			'template.form.privacy.part2': 'privacy policy',
			'template.form.privacy.part3': 'and agree to the data treatment.',
			'template.form.privacy.yes': 'Yes',
			'template.form.privacy.no': 'No',
			'template.form.error': 'The user email is already registered in the identity provider.',
			'template.form.error.message1': 'Please go back and login.',
			'template.form.error.message2': 'Please insert your current password to continue.',
			'template.form.required': 'Required field.',
			'template.form.btn.signup': 'Signup',
			'template.tokenFlow.welcome': 'Welcome, ',
			'template.tokenFlow.userAlreadyRegistered': 'The account is already registered on ',
			'template.tokenFlow.error.message1': 'Please go back to log in or click the button below to sign up with another account.',
			'template.tokenFlow.error.message2': 'To proceed with this account click on \'Continue\'. To sign up with another account click on \'New registration\'.',
			'template.tokenFlow.btn.restart': 'New registration',
			'template.tokenFlow.btn.continue': 'Continue'
		}
	};

	constructor(
		private route: ActivatedRoute,
		private authService: AuthService,
		private clientService: ClientService,
		private roleService: RoleService,
		private userService: UserService,
		private municipalityService: MunicipalityService,
		private loaderService: LoaderService,
		private notificationsService: NotificationsService
	) {
	}

	ngOnInit(): void {

		const clientId: string = this.route.snapshot.paramMap.get('clientId');
		const roleName: string = this.route.snapshot.paramMap.get('roleName');

		this.organizationTypes = this.route.snapshot.data.organizationTypes;
		this.provinces = this.route.snapshot.data.provinces;
		this.language = this.route.snapshot.data.language;

		this.user = new User();
		this.user.email = '';
		this.user.password = '';
		this.user.passwordConfirm = '';
		this.user.organizationType = this.organizationTypes.filter(ot => ot.name === 'Altro')[0];
		this.user.municipality = new Municipality();

		this.userAlreadyExists = false;
		this.userHasClient = false;
		this.validToken = false;

		this.loaderService.displayLoader();

		const clientObservable = this.clientService.getClientByClientId(clientId);
		const roleObservable = this.roleService.getRoleByName(roleName);

		forkJoin([clientObservable, roleObservable]).subscribe(
			res => {
				this.client = res[0];
				this.user.role = res[1].name;

				this.clientUI = ClientService.getClientUI(this.client);

				if (!this.user.organizationType) {
					this.user.organizationType = this.organizationTypes.filter(ot => ot.name === 'Altro')[0];
				}

				if (this.user.municipality && this.user.municipality.province !== '') {
					this.updateMunicipality(new MatSelectChange(null, this.user.municipality.province));
				}

				if (this.user.attributes == null) {
					this.user.attributes = {};
				}

				if (AuthService.hasToken()) {
					this.authService.verifyUserTokenAndClient(AuthService.getToken(), clientId).subscribe(validityResponse => {
						if (validityResponse.token === true) {
							this.validToken = true;
							this.userHasClient = !validityResponse.client;

							if (validityResponse.user) {
								this.recognizedUser = new User();
								this.recognizedUser.name = validityResponse.user.name;
								this.recognizedUser.surname = validityResponse.user.surname;
								this.recognizedUser.email = validityResponse.user.email;
							}
						}
					});
				}

				this.loaderService.hideLoader();
			},
			error => {
				this.loaderService.hideLoader();
				this.notificationsService.error('Invalid parameters', 'Unable to create signup form');
			}
		);
	}

	signup(): void {
		this.loaderService.displayLoader();

		let signupObservable: Observable<boolean>;
		if (this.userAlreadyExists) {
			signupObservable = this.userService.signupExistingUser(this.user, this.client.clientId, this.language);
		} else {
			signupObservable = this.userService.signupNewUser(this.user, this.client.clientId, this.language);
		}

		signupObservable.subscribe(
			res => {
				this.loaderService.hideLoader();

				if (res) {
					this.notificationsService.success('Create account', 'Account created successfully. Check your e-mail to validate your profile.');
					setTimeout(() => {
						this.userService.getUserByEmail(this.user.email).subscribe(createdUser => {
							const payload = {
								oldEmail: createdUser.email,
								email: createdUser.email,
								role: createdUser.role,
								name: createdUser.name,
								surname: createdUser.surname,
								organization: createdUser.organization,
								organizationType: createdUser.organizationType,
								attributes: createdUser.attributes,
								status: 'create'
							};
							window.location.href = this.client.profileCallbackUrl + '?user=' + encodeURIComponent(JSON.stringify(payload));
						});
					}, 2500);
				} else {
					this.notificationsService.error('Signup error', 'Unable to create account');
				}
			},
			error => {
				this.notificationsService.error('Signup error', 'Unable to create account. ' + error);
				this.loaderService.hideLoader();
			}
		);
	}

	continueSignup(): void {
		this.loaderService.displayLoader();

		this.userService.getUserByEmail(this.recognizedUser.email).subscribe(createdUser => {
			const payload = {
				oldEmail: createdUser.email,
				email: createdUser.email,
				role: createdUser.role,
				name: createdUser.name,
				surname: createdUser.surname,
				organization: createdUser.organization,
				organizationType: createdUser.organizationType,
				attributes: createdUser.attributes,
				status: 'create'
			};

			this.loaderService.hideLoader();
			window.location.href = this.client.profileCallbackUrl + '?user=' + encodeURIComponent(JSON.stringify(payload));
		});
	}

	resetToken(): void {
		AuthService.removeToken();
		AuthService.removeUser();
		window.location.reload();
	}

	verifyUser(): void {
		if (this.user.email.trim() !== '') {
			this.userService.getUserByEmail(this.user.email).subscribe(res => {
				if (res !== null) {
					this.userAlreadyExists = true;
					this.userHasClient = res.clients.filter(c => c.clientId === this.client.clientId).length > 0;
				} else {
					this.userAlreadyExists = false;
					this.userHasClient = false;
				}
			});
		} else {
			this.userAlreadyExists = false;
			this.userHasClient = false;
		}
	}

	setPassword(password: string): void {
		this.user.password = password;
	}

	setPasswordConfirm(password: string): void {
		this.user.passwordConfirm = password;
	}

	updateMunicipality(event: MatSelectChange): void {
		const province = event.value;

		if (province !== '') {
			this.municipalityService.getMunicipalities(province).subscribe(res => {
				this.municipalities = res;
			});
		}
	}

	updateCheckboxCustomField(event: MatCheckboxChange, attributeName: string, optionValue: any): void {
		if (!this.user.attributes[attributeName]) {
			this.user.attributes[attributeName] = [];
		}

		if (event.checked) {
			if (!this.user.attributes[attributeName].includes(optionValue)) {
				this.user.attributes[attributeName].push(optionValue);
			}
		} else {
			this.user.attributes[attributeName].splice(this.user.attributes[attributeName].indexOf(optionValue), 1);
		}

		// console.log(this.user.attributes[attributeName]);
	}

	getCheckboxCustomFieldValue(attributeName: string, optionValue: any): boolean {
		return this.user.attributes[attributeName] && this.user.attributes[attributeName].includes(optionValue);
	}

	getLabel(key: string): string {
		return this.labels[this.language][key];
	}
}
