import {Component, OnInit} from '@angular/core';
import {User} from '../../../../model/user.model';
import {Client} from '../../../../model/client.model';
import {ActivatedRoute} from '@angular/router';
import {ClientService} from '../../../../services/client.service';
import {UserService} from '../../../../services/user.service';
import {LoaderService} from '../../../../services/loader.service';
import {NotificationsService} from 'angular2-notifications';
import {forkJoin} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ChangePasswordComponent} from '../../../dialogs/hmi/change-password/change-password.component';
import {OrganizationType} from '../../../../model/organization-type.model';
import {Municipality} from '../../../../model/municipality.model';
import {MatSelectChange} from '@angular/material/select';
import {MunicipalityService} from '../../../../services/municipality.service';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

	user: User;
	client: Client;
	organizationTypes: OrganizationType[];
	provinces: Municipality[];
	municipalities: Municipality[];

	clientUI: any;

	disableActions: boolean;
	originalEmail: string;

	public language: string;
	public labels = {
		'IT': {
			'template.editProfile': 'MODIFICA PROFILO',
			'template.personalInfo': 'Informazioni personali',
			'template.form.choose': 'Scegli...',
			'template.form.name': 'Nome',
			'template.form.surname': 'Cognome',
			'template.form.address': 'Indirizzo',
			'template.form.organization': 'Organizzazione',
			'template.form.province': 'Provincia',
			'template.form.municipality': 'Comune',
			'template.form.organizationType': 'Tipo di Organizzazione',
			'template.form.phone': 'Telefono',
			'template.form.credential': 'Credenziali',
			'template.form.email.hint': 'Un cambio di e-mail comporta la sua nuova verifica',
			'template.form.password.hint': 'Clicca qui per modificare la password',
			'template.form.otherInformation': 'Altre informazioni',
			'template.form.privacy.title': 'PRIVACY POLICY',
			'template.form.privacy.part1': 'Dichiaro di aver letto la',
			'template.form.privacy.part2': 'privacy policy',
			'template.form.privacy.part3': 'e di accettare il trattamento dei dati.',
			'template.form.privacy.yes': 'Sì',
			'template.form.privacy.no': 'No',
			'template.form.btn.cancel': 'Annulla',
			'template.form.btn.update': 'Aggiorna il profilo',
			'notification.invalid.title': 'Parametri non validi',
			'notification.invalid.message': 'Non è possibile creare il modulo per il profilo.',
			'notification.edit.title': 'Modifica del profilo',
			'notification.edit.message1': 'L\'account è stato aggiornato con successo.',
			'notification.edit.message2': 'Non è stato possibile aggiornare l\'account.'
		},
		'EN': {
			'template.editProfile': 'EDIT PROFILE',
			'template.personalInfo': 'Personal info',
			'template.form.choose': 'Choose...',
			'template.form.name': 'Name',
			'template.form.surname': 'Surname',
			'template.form.address': 'Address',
			'template.form.organization': 'Organization',
			'template.form.province': 'Province',
			'template.form.municipality': 'Municipality',
			'template.form.organizationType': 'Organization Type',
			'template.form.phone': 'Phone',
			'template.form.credential': 'Credential',
			'template.form.email.hint': 'A change of e-mail involves its re-verification',
			'template.form.password.hint': 'Click here to modify your password',
			'template.form.otherInformation': 'Other information',
			'template.form.privacy.title': 'PRIVACY POLICY',
			'template.form.privacy.part1': 'I declare that I have read the',
			'template.form.privacy.part2': 'privacy policy',
			'template.form.privacy.part3': 'and agree to the data treatment.',
			'template.form.privacy.yes': 'Yes',
			'template.form.privacy.no': 'No',
			'template.form.btn.cancel': 'Cancel',
			'template.form.btn.update': 'Update profile',
			'notification.invalid.title': 'Invalid parameters',
			'notification.invalid.message': 'Unable to create profile form.',
			'notification.edit.title': 'Edit profile',
			'notification.edit.message1': 'Account updated successfully.',
			'notification.edit.message2': 'Unable to update account.'
		}
	};

	constructor(
		private route: ActivatedRoute,
		private clientService: ClientService,
		private userService: UserService,
		private municipalityService: MunicipalityService,
		private loaderService: LoaderService,
		private notificationsService: NotificationsService,
		private changePasswordDialog: MatDialog
	) {
	}

	ngOnInit(): void {

		const clientId: string = this.route.snapshot.paramMap.get('clientId');
		this.organizationTypes = this.route.snapshot.data.organizationTypes;
		this.provinces = this.route.snapshot.data.provinces;
		this.language = this.route.snapshot.data.language;

		this.user = new User();
		this.user.email = '';
		this.user.password = '';
		this.user.organizationType = this.organizationTypes.filter(ot => ot.name === 'Altro')[0];
		this.user.municipality = new Municipality();

		this.disableActions = false;

		this.loaderService.displayLoader();

		const clientObservable = this.clientService.getClientByClientId(clientId);
		const userObservable = this.userService.getCurrentUser();

		forkJoin([clientObservable, userObservable]).subscribe(
			res => {
				this.client = res[0];
				this.user = res[1];
				this.originalEmail = this.user.email;

				this.clientUI = ClientService.getClientUI(this.client);

				if (!this.user.organizationType) {
					this.user.organizationType = this.organizationTypes.filter(ot => ot.name === 'Altro')[0];
				}

				if (this.user.municipality == null) {
					this.user.municipality = new Municipality();
				}

				if (this.user.municipality && this.user.municipality.province !== '') {
					this.updateMunicipality(new MatSelectChange(null, this.user.municipality.province));
				}

				if (this.user.attributes == null) {
					this.user.attributes = {};
				}

				this.loaderService.hideLoader();
			},
			error => {
				this.disableActions = true;
				this.loaderService.hideLoader();
				this.notificationsService.error(this.getLabel('notification.invalid.title'), this.getLabel('notification.invalid.message'));
			}
		);
	}

	updateProfile(): void {
		this.loaderService.displayLoader();

		this.userService.updateUser(this.user).subscribe(
			res => {
				this.loaderService.hideLoader();

				if (res) {
					this.notificationsService.success(this.getLabel('notification.edit.title'), this.getLabel('notification.edit.message1'));
					setTimeout(() => {
						this.userService.getCurrentUser().subscribe(updatedUser => {
							const payload = {
								oldEmail: this.originalEmail,
								email: updatedUser.email,
								role: updatedUser.role,
								name: updatedUser.name,
								surname: updatedUser.surname,
								organization: updatedUser.organization,
								organizationType: updatedUser.organizationType,
								attributes: updatedUser.attributes,
								status: 'update'
							};
							window.location.href = this.buildUrl(this.client.profileCallbackUrl, encodeURIComponent(JSON.stringify(payload)));
						});
					}, 1000);
				} else {
					this.notificationsService.error(this.getLabel('notification.edit.title'), this.getLabel('notification.edit.message2'));
				}
			},
			error => {
				this.notificationsService.error(this.getLabel('notification.edit.title'), this.getLabel('notification.edit.message2') + ' ' + error);
				this.loaderService.hideLoader();
			}
		);
	}

	openChangePasswordDialog(): void {
		this.changePasswordDialog.open(
			ChangePasswordComponent, {
				width: '500px',
				data: {
					clientUI: this.clientUI,
					language: this.language
				}
			}
		);
	}

	goBack(): void {
		window.location.href = this.client.profileCallbackUrl;
	}

	updateMunicipality(event: MatSelectChange): void {
		const province = event.value;

		if (province !== '') {
			this.municipalityService.getMunicipalities(province).subscribe(res => {
				this.municipalities = res;
			});
		}
	}

	private buildUrl(url: string, user: string): string {
		let prefix = '?';
		if (url.indexOf('?') > 0) {
			prefix = '&';
		}

		return url + prefix + 'user=' + user;
	}

	updateCheckboxCustomField(event: MatCheckboxChange, attributeName: string, optionValue: any): void {
		if (!this.user.attributes[attributeName]) {
			this.user.attributes[attributeName] = [];
		}

		if (event.checked) {
			if (!this.user.attributes[attributeName].includes(optionValue)) {
				this.user.attributes[attributeName].push(optionValue);
			}
		} else {
			this.user.attributes[attributeName].splice(this.user.attributes[attributeName].indexOf(optionValue), 1);
		}

		// console.log(this.user.attributes[attributeName]);
	}

	getCheckboxCustomFieldValue(attributeName: string, optionValue: any): boolean {
		return this.user.attributes[attributeName] && this.user.attributes[attributeName].includes(optionValue);
	}

	getLabel(key: string): string {
		return this.labels[this.language][key];
	}
}
