<app-page [displayMenuIcon]="true" [displayAdditionalIcons]="true">

  <mat-card class="spacer">
    <!--<mat-card-title></mat-card-title>-->
    <mat-card-content class="mat-headline">
      <div *ngIf="loggedUser">
        Welcome, {{loggedUser.name}} {{loggedUser.surname}} ({{loggedUser.role}})!<br/>
        Click <a routerLink="/profile/idp">here</a> to edit your profile.
      </div>

      <!--
      <button mat-button (click)="displayLoader()">Loader</button>
      <button mat-button (click)="displayNotification()">Notification</button>

      <ul>
        <li><a href="login/client-001">Login</a></li>
        <li><a href="signup/client-001/Administrator">Registrazione utente</a></li>
        <li><a href="profile/client-001">Modifica profilo</a></li>
        <li><a href="reset-password/ae2b57db-12e7-4d95-aba1-aa70025af2fe">Reset della password</a></li>
        <li><a href="validate-email/9f734220-a8fa-48bf-a9aa-181919c66bc5">Validazione e-mail</a></li>
        <li><a href="logout/client-001">Logout</a></li>
        <li><a href="delete/client-001">Rimozione profilo (locale)</a></li>
        <li><a href="delete-all/client-001">Rimozione profilo (globale)</a></li>
      </ul>

      <app-password label="Password" name="password" [value]="passwordTest" (valueChange)="logPassword($event)"></app-password>
      -->
    </mat-card-content>
  </mat-card>

  <mat-grid-list [cols]="statsColumns" rowHeight="200px" gutterSize="20px" (window:resize)="onResize()">
    <mat-grid-tile (click)="openPage('clients')" class="clickable">
      <mat-card class="stats">
        <mat-card-title class="header">
          <div class="header">
            <mat-icon class="header">web_asset</mat-icon>
          </div>
          <div class="title">
            Clients
          </div>
        </mat-card-title>
        <mat-card-content class="stats" *ngIf="stats">
			  {{stats.format(stats.applications)}}
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile (click)="openPage('users')" class="clickable">
      <mat-card class="stats">
        <mat-card-title class="header">
          <div class="header">
            <mat-icon class="header">people_alt</mat-icon>
          </div>
          <div class="title">
            Users
          </div>
        </mat-card-title>
        <mat-card-content class="stats" *ngIf="stats">
			  {{stats.format(stats.users)}}
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-card class="stats">
        <mat-card-title class="header">
          <div class="header">
            <mat-icon class="header">generating_tokens</mat-icon>
          </div>
          <div class="title">
            Active sessions
          </div>
        </mat-card-title>
        <mat-card-content class="stats" *ngIf="stats">
			  {{stats.format(stats.sessions)}}
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>

</app-page>
