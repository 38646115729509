import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {Constants} from '../env/constants';
import {map} from 'rxjs/operators';
import {User} from '../model/user.model';
import {SearchFilter} from '../model/search-filter.model';
import {SearchResults} from '../model/search-results.model';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(private http: HttpService) {
	}

	public sendResetPasswordEmail(email: string): Observable<boolean> {
		return this.http.post(Constants.HMI.POST_SEND_RESET_PASSWORD_EMAIL, {email: email}, false, {email: email}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public updatePassword(passwords: any): Observable<boolean> {
		return this.http.put(Constants.HMI.PUT_UPDATE_PASSWORD, passwords, false).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public getUserByEmail(email: string): Observable<User> {
		return this.http.get(Constants.HMI.GET_USER, {email: email}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public getCurrentUser(): Observable<User> {
		return this.http.get(Constants.HMI.GET_CURRENT_USER).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public updateUser(user: User): Observable<boolean> {
		return this.http.put(Constants.HMI.PUT_UPDATE_USER, user, false).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public signupNewUser(user: User, clientId: string, language: string): Observable<boolean> {
		return this.http.post(Constants.HMI.POST_SIGNUP, user, false, {clientId: clientId, language: language.toLowerCase()}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public signupExistingUser(user: User, clientId: string, language: string): Observable<boolean> {
		return this.http.put(Constants.HMI.PUT_SIGNUP, user, false, {clientId: clientId, language: language.toLowerCase()}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public validateEmail(validationToken: string): Observable<User> {
		return this.http.post(Constants.HMI.POST_VALIDATE_EMAIL, {}, false, {validationToken: validationToken}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public resetPassword(user: User, resetToken: string): Observable<User> {
		return this.http.post(Constants.HMI.POST_RESET_PASSWORD, user, false, {resetToken: resetToken}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public deleteUserLocally(clientId: string, language: string): Observable<boolean> {
		return this.http.delete(Constants.HMI.DELETE_USER_LOCALLY, {clientId: clientId, language: language.toLowerCase()}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public deleteUserGlobally(language: string): Observable<boolean> {
		return this.http.delete(Constants.HMI.DELETE_USER_GLOBALLY, {language: language.toLowerCase()}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public getUser(userId: number): Observable<User> {
		return this.http.get(Constants.ADMIN.GET_USER, {userId: userId}).pipe(
			map(response => {
				if (response.valid) {
					return new User(response.payload);
				} else {
					throw response.message;
				}
			})
		);
	}

	public search(filter: SearchFilter): Observable<SearchResults> {
		return this.http.put(Constants.ADMIN.PUT_SEARCH_USERS, filter, false).pipe(
			map(response => {
				if (response.valid) {
					const res = new SearchResults(response.payload, 'User');
					res.initPagination(filter.pageNumber);

					return res;
				} else {
					throw response.message;
				}
			})
		);
	}

	public save(user: User): Observable<number> {
		if (user.id > 0) {
			return this.http.put(Constants.ADMIN.PUT_USER, user, false, {userId: user.id}).pipe(
				map(response => {
					if (response.valid) {
						return response.payload;
					} else {
						throw {message: response.message, errorFields: response.errorFields};
					}
				})
			);
		} else {
			return this.http.post(Constants.ADMIN.POST_USER, user, false).pipe(
				map(response => {
					if (response.valid) {
						return response.payload;
					} else {
						throw {message: response.message, errorFields: response.errorFields};
					}
				})
			);
		}
	}

	public delete(userId: number): Observable<boolean> {
		return this.http.delete(Constants.ADMIN.DELETE_USER, {userId: userId}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw {message: response.message, errorFields: response.errorFields};
				}
			})
		);
	}

	public updatePasswordAsAdmin(passwords: any): Observable<boolean> {
		return this.http.put(Constants.ADMIN.PUT_UPDATE_PASSWORD, passwords, false).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public export(filter: SearchFilter): Observable<any> {
		return this.http.download(Constants.ADMIN.PUT_EXPORT_USERS, filter);
	}
}
