import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoaderService} from '../../../../services/loader.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
	selector: 'app-profile-dispatcher',
	templateUrl: './profile-dispatcher.component.html',
	styleUrls: ['./profile-dispatcher.component.scss']
})
export class ProfileDispatcherComponent implements OnInit {

	constructor(private router: Router, private loaderService: LoaderService) {
	}

	ngOnInit(): void {
		this.loaderService.displayLoader();

		const user = AuthService.getUser();
		let url: string;

		if (user.role === 'Developer') {
			url = '/admin/home?from=profile';
		} else {
			url = '/admin/analytics?from=profile';
		}

		this.router.navigateByUrl(url).then(() => {
			this.loaderService.hideLoader();
		});
	}

}
