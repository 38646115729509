import {Client} from './client.model';
import {OrganizationType} from './organization-type.model';
import {Municipality} from './municipality.model';

export class User {
	id: number;
	email: string;
	password: string;
	passwordConfirm: string;
	name: string;
	surname: string;
	organization: string;
	organizationType: OrganizationType;
	phone: string;
	address: string;
	municipality: Municipality;
	emailConfirmed: boolean;
	role: string;
	clients: Client[];
	registeredClient: boolean;
	blockchainId: string;
	registrationDate: number;
	privacyPolicy: boolean;
	attributes: any;

	constructor(e?: any) {
		this.id = e && e.id !== undefined ? e.id : null;
		this.email = e && e.email !== undefined ? e.email : null;
		this.password = e && e.password !== undefined ? e.password : null;
		this.passwordConfirm = e && e.passwordConfirm !== undefined ? e.passwordConfirm : null;
		this.name = e && e.name !== undefined ? e.name : null;
		this.surname = e && e.surname !== undefined ? e.surname : null;
		this.organization = e && e.organization !== undefined ? e.organization : null;
		this.organizationType = e && e.organizationType !== undefined ? new OrganizationType(e.organizationType) : null;
		this.phone = e && e.phone !== undefined ? e.phone : null;
		this.address = e && e.address !== undefined ? e.address : null;
		this.municipality = e && e.municipality !== undefined ? new Municipality(e.municipality) : null;
		this.emailConfirmed = e && e.emailConfirmed !== undefined ? e.emailConfirmed : null;
		this.role = e && e.role !== undefined ? e.role : null;
		this.clients = e && e.clients !== undefined ? this.buildClients(e.clients) : null;
		this.registeredClient = e && e.registeredClient !== undefined ? e.registeredClient : null;
		this.blockchainId = e && e.blockchainId !== undefined ? e.blockchainId : null;
		this.registrationDate = e && e.registrationDate !== undefined ? e.registrationDate : new Date().getTime();
		this.privacyPolicy = e && e.privacyPolicy !== undefined ? e.privacyPolicy : null;
		this.attributes = e && e.attributes !== undefined ? e.attributes : {};
	}

	private buildClients(clients: any[]): Client[] {
		return clients.map(client => new Client(client));
	}
}
