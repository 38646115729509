import {Component, OnInit} from '@angular/core';
import {MenuItem} from '../../../model/menu-item.model';
import {AuthService} from '../../../services/auth.service';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

	menu: Array<MenuItem>;

	constructor() {
	}

	ngOnInit(): void {

		const user = AuthService.getUser();

		this.menu = new Array<MenuItem>();

		if (user && user.role === 'Developer') {
			this.menu.push(new MenuItem('home', 'Home', '/admin/home'));
			this.menu.push(new MenuItem('web_asset', 'Clients', '/admin/clients'));
			this.menu.push(new MenuItem('people_alt', 'Users', '/admin/users'));
			this.menu.push(new MenuItem('group_work', 'Roles', '/admin/roles'));
		}

		this.menu.push(new MenuItem('dashboard', 'Analytics', '/admin/analytics'));
		this.menu.push(new MenuItem('help_outline', 'Help', null, [
			new MenuItem('feedback', 'Feedback', 'mailto:idp@enea.it?subject=Identity Provider'),
			new MenuItem('accessibility', 'Accessibility', 'https://form.agid.gov.it/view/8b355a43-9064-4193-a1ad-ff9da425718c/'),
			new MenuItem('policy', 'Privacy', '/#/privacy-policy')
		]));
	}

	gotoLink(url: string): void {
		window.open(url, '_blank');
	}
}
