import {User} from './user.model';

export class LoginResponse {
	token: string;
	user: User;

	constructor(token: string, user?: User) {
		this.token = token;
		this.user = user;
	}
}
