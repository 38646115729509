import {Component, OnInit} from '@angular/core';
import {ActionColumnConfiguration, TableConfiguration} from '../../../../model/table-configuration.model';
import {SearchFilter} from '../../../../model/search-filter.model';
import {SearchResults} from '../../../../model/search-results.model';
import {ClientService} from '../../../../services/client.service';
import {NotificationsService} from 'angular2-notifications';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DeleteClientComponent} from '../../../dialogs/admin/delete-client/delete-client.component';
import {Client} from '../../../../model/client.model';

@Component({
	selector: 'app-clients-admin',
	templateUrl: './clients-admin.component.html',
	styleUrls: ['./clients-admin.component.scss']
})
export class ClientsAdminComponent implements OnInit {

	tableConfiguration: TableConfiguration;
	searchFilter: SearchFilter;
	searchResults: SearchResults;

	constructor(
		private clientService: ClientService,
		private notificationsService: NotificationsService,
		private router: Router,
		private deleteDialog: MatDialog
	) {
	}

	ngOnInit(): void {
		this.searchFilter = new SearchFilter();
		this.searchFilter.pageNumber = 1;
		this.searchFilter.pageSize = '10';
		this.searchFilter.sortField = 'name';
		this.searchFilter.sortDirection = 'ASC';

		this.tableConfiguration = new TableConfiguration();
		this.tableConfiguration.name = 'Clients';
		this.tableConfiguration.searchFilter = this.searchFilter;
		this.tableConfiguration.searchResults = this.searchResults;
		this.tableConfiguration.columns = [
			{name: 'name', label: 'Client name', sortable: true},
			{name: 'clientId', label: 'Client ID', sortable: false}
		];
		this.tableConfiguration.actionColumn = new ActionColumnConfiguration('Edit user attributes', 'manage_accounts');

		this.search();
	}

	updateSearchField(field: string, value: any): void {
		this.searchFilter.fields[field] = value;
	}

	getSearchFieldValue(field: string): any {
		return this.searchFilter.fields[field];
	}

	updatePageSize(): void {
		this.search();
	}

	updateSortField(field: string): void {
		this.search();
	}

	updatePagination(pageNumber: number): void {
		this.search();
	}

	search(): void {
		this.clientService.search(this.searchFilter).subscribe(res => {
			this.tableConfiguration.searchResults = res;
			this.searchResults = res;
		});
	}

	edit(id: number): void {
		this.router.navigateByUrl('/admin/clients/' + id).then(() => {
		});
	}

	editUserAttributes(id: number): void {
		this.router.navigateByUrl('/admin/clients/' + id + '/user-attributes').then(() => {
		});
	}


	delete(id: number): void {
		const dialog = this.deleteDialog.open(
			DeleteClientComponent, {
				width: '500px'
			}
		);

		dialog.afterClosed().subscribe(res => {
			if (res && res === true) {
				this.clientService.delete(id).subscribe(
					deleteResult => {
						if (deleteResult) {
							this.notificationsService.success('Delete client', 'Client removed successfully.');
							this.search();
						} else {
							this.notificationsService.error('Delete client', 'Unable to remove client.');
						}
					},
					error => {
						this.notificationsService.error('Delete client', 'Unable to remove client: ' + error);
					});
			}
		});
	}

	verifyClient(client: Client): boolean {
		return client.clientId === 'idp';
	}
}
