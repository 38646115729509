import {Component, OnInit} from '@angular/core';
import {LoaderService} from '../../../../services/loader.service';
import {NotificationsService} from 'angular2-notifications';
import {AuthService} from '../../../../services/auth.service';
import {User} from '../../../../model/user.model';
import {StatsService} from '../../../../services/stats.service';
import {StatsResponse} from '../../../../model/stats-response.model';
import {Router} from '@angular/router';

@Component({
	selector: 'app-dashboard',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	// public passwordTest: any;
	public loggedUser: User;
	public stats: StatsResponse;
	public statsColumns: number;

	constructor(
		private router: Router,
		private loaderService: LoaderService,
		private notificationService: NotificationsService,
		private authService: AuthService,
		private statsService: StatsService) {
	}

	ngOnInit(): void {
		// this.passwordTest = 'xxx';
		this.loggedUser = AuthService.getUser();

		this.authService.isLogged.subscribe(res => {
			if (res) {
				this.loggedUser = AuthService.getUser();
			}
		});

		this.statsService.getStats().subscribe(res => {
			if (res) {
				this.stats = res;
			}
		});

		this.setColumns(window.innerWidth);
	}

	onResize(): void {
		this.setColumns(window.innerWidth);
	}

	setColumns(innerWidth): void {
		if (innerWidth > 750) {
			this.statsColumns = 3;
		} else if (innerWidth > 560) {
			this.statsColumns = 2;
		} else {
			this.statsColumns = 1;
		}
	}

	openPage(page): void {
		this.router.navigateByUrl('/admin/' + page).then(() => {
		});
	}

	/*displayLoader(): void {
		this.loaderService.displayLoader();
		setTimeout(() => {
			this.loaderService.hideLoader();
		}, 1500);
	}

	displayNotification(): void {
		this.notificationService.info('Info', 'Message');
		this.notificationService.success('Success', 'Message');
		this.notificationService.warn('Warning', 'Message');
		this.notificationService.error('Error', 'Message');
	}

	logPassword(password): void {
		console.log(this.passwordTest, password);
	}*/
}
