import {Component, OnInit} from '@angular/core';
import {User} from '../../../../model/user.model';
import {Client} from '../../../../model/client.model';
import {ActivatedRoute} from '@angular/router';
import {ClientService} from '../../../../services/client.service';
import {UserService} from '../../../../services/user.service';
import {LoaderService} from '../../../../services/loader.service';
import {forkJoin, Observable} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import {AuthService} from '../../../../services/auth.service';

@Component({
	selector: 'app-delete',
	templateUrl: './delete.component.html',
	styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {

	public clientUI: any;

	public language: string;
	public labels = {
		'IT': {
			'template.title': 'RIMOZIONE DEL PROFILO',
			'template.message': 'Attendere che l\'account venga rimosso dal sistema...',
			'notification.title': 'Rimozione del profilo',
			'notification.message.ok': 'L\'account è stato rimosso con successo.',
			'notification.message.error': 'Non è stato possibile rimuovere l\'account.'
		},
		'EN': {
			'template.title': 'DELETE PROFILE',
			'template.message': 'Please wait while your account is removed from the system...',
			'notification.title': 'Delete profile',
			'notification.message.ok': 'Account removed successfully.',
			'notification.message.error': 'Unable to remove account.'
		}
	};

	constructor(
		private route: ActivatedRoute,
		private clientService: ClientService,
		private userService: UserService,
		private loaderService: LoaderService,
		private notificationsService: NotificationsService
	) {
	}

	ngOnInit(): void {
		this.loaderService.displayLoader();

		this.language = this.route.snapshot.data.language;

		const clientId: string = this.route.snapshot.paramMap.get('clientId');
		const clientObservable = this.clientService.getClientByClientId(clientId);
		const userObservable = this.userService.getCurrentUser();

		let deleteObservable: Observable<boolean>;
		if (this.route.snapshot.routeConfig.path === 'delete/:clientId') {
			deleteObservable = this.userService.deleteUserLocally(clientId, this.language);
		} else if (this.route.snapshot.routeConfig.path === 'delete-all/:clientId') {
			deleteObservable = this.userService.deleteUserGlobally(this.language);
		}

		forkJoin([clientObservable, userObservable, deleteObservable]).subscribe(
			res => {
				const client: Client = res[0];
				const user: User = res[1];
				const deleteResult = res[2];

				this.clientUI = ClientService.getClientUI(client);

				this.loaderService.hideLoader();

				if (deleteResult) {
					AuthService.removeToken();

					setTimeout(() => {
						this.notificationsService.success(this.getLabel('notification.title'), this.getLabel('notification.message.ok'));

						const payload = {
							oldEmail: user.email,
							email: user.email,
							role: user.role,
							name: user.name,
							surname: user.surname,
							organization: user.organization,
							status: 'delete'
						};
						window.location.href = client.profileCallbackUrl + '?user=' + encodeURIComponent(JSON.stringify(payload));
					}, 1000);
				} else {
					setTimeout(() => {
						this.notificationsService.error(this.getLabel('notification.title'), this.getLabel('notification.message.error'));
					}, 1000);
				}

			},
			error => {
				this.loaderService.hideLoader();
				this.notificationsService.error(this.getLabel('notification.title'), this.getLabel('notification.message.error') + ' ' + error);
			});
	}

	getLabel(key: string): string {
		return this.labels[this.language][key];
	}

}
